<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput size="sm" v-model="customer_name" placeholder="Name"/>
        </CCol>
        <CCol lg="3">
          <CInput size="sm" v-model="contact_no" placeholder="Contact No."/>
        </CCol>
         <!-- <CCol lg="3">
          <CInput size="sm" v-model="account_code" placeholder="Account Code"/>
        </CCol> -->
        <!-- <CCol lg="3">
          <CInput size="sm" v-model="email" placeholder="Email"/>
        </CCol> -->
        <CCol lg="3">
          <CInput size="sm" v-model="address_a" placeholder="Address"/>
        </CCol>
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="3">
          <!-- <CInput size="sm" v-model="contact_no" placeholder="Contact No."/> -->
        </CCol>
        <CCol lg="3">
        
        </CCol>
        <CCol lg="3">
        </CCol>
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow>
  </div>
</template>

<script>
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            customer_name: "",
            email: "",
            address_a: "",
            contact_no: "",
            account_code: ""
          }
        },
      mounted() {
      },
      methods: {
        search() {
          const data = {
            customer_name: this.customer_name,
            email: this.email,
            address_a: this.address_a,
            contact_no: this.contact_no,
            account_code: this.account_code,
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.customer_name = "";
          this.email = "";
          this.address_a = "";
          this.contact_no = "";
          this.account_code = "";
          const data = {
            customer_name: this.customer_name,
            email: this.email,
            address_a: this.address_a,
            contact_no: this.contact_no,
            account_code: this.account_code,
          }
          this.$emit("depot-search-query", data)
        }
      }
    }
</script>

<style scoped>

</style>
